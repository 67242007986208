import { ReactNode } from 'react'
import { useRouter } from 'next/navigation'
import { signOut } from 'aws-amplify/auth'

import { ClipLoader, usePersonnel, useUser } from '@/components'

import CompanyNav from './CompanyNav'
import BusinessAdvisorNav from './BusinessAdvisorNav'

const Nav = ({ children }: { children: ReactNode }) => {
  const router = useRouter()
  const { user, loading, setUser, setCompanyProfile } = useUser()
  const { setPersonnel } = usePersonnel()

  const logout = async () => {
    try {
      await signOut()
      sessionStorage.clear()
      setUser(null)
      setCompanyProfile(null)
      setPersonnel([])
      router.push('/auth/login')
    } catch (error) {
      alert(error)
    }
  }

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <ClipLoader />
      </div>
    )
  }

  return user?.attributes['custom:accountType'] === 'ba' ? (
    <BusinessAdvisorNav logout={logout}>{children}</BusinessAdvisorNav>
  ) : (
    <CompanyNav logout={logout}>{children}</CompanyNav>
  )
}

export default Nav
