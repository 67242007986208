import { FieldErrors, UseFieldArrayRemove, UseFormRegister } from 'react-hook-form'

import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/solid'

import { Combobox, usePersonnel } from '@/components'
import { Field, Input, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/catalyst'
import { formatCurrency, formatPercent } from '@/components/salary-calculator/lib/numbro'

import { resourcePlanType, resourceType } from './schemas'
import {
  getFullyBurdenedCost,
  getHourlyProfit,
  getMaxProjectedProfit,
  getRevenue
} from '../../salary-calculator/lib/calculations'

const getCalculations = (billRate: string, billableHours: string, fullyBurdenedRate: string) => {
  const billRateNum = Number(billRate)
  const billableHoursNum = Number(billableHours)
  const fullyBurdenedRateNum = Number(fullyBurdenedRate)

  const revenue = getRevenue(billRateNum, billableHoursNum)
  const hourlyProfit = getHourlyProfit(billRateNum, fullyBurdenedRateNum)
  const fullyBurdenedCost = getFullyBurdenedCost(fullyBurdenedRateNum, billableHoursNum)
  const profit = getMaxProjectedProfit(billRateNum, fullyBurdenedCost, billableHoursNum)

  return {
    revenue,
    hourlyProfit,
    profit
  }
}

const CreateSelfGuidedResourcePlanTable = ({
  fields,
  values,
  errors,
  register,
  append,
  remove
}: {
  fields: { id: string }[]
  values: resourceType[]
  errors: FieldErrors<resourcePlanType>
  register: UseFormRegister<resourcePlanType>
  append: () => void
  remove: UseFieldArrayRemove
}) => {
  let totalBillRate = 0
  let totalFullyBurdenedRate = 0
  let totalRevenue = 0
  let totalHourlyProfit = 0
  let totalProfit = 0

  const { personnel } = usePersonnel()

  return (
    <div className="flex flex-col">
      {fields && fields.length > 0 && (
        <Table grid dense>
          <TableHead>
            <TableRow>
              <TableHeader></TableHeader>
              <TableHeader className="min-w-56">Name</TableHeader>
              <TableHeader className="min-w-36">Role</TableHeader>
              <TableHeader className="min-w-24">Hours</TableHeader>
              <TableHeader className="min-w-32">Bill Rate</TableHeader>
              <TableHeader className="min-w-32">Fully Burdened Rate</TableHeader>
              <TableHeader>Revenue</TableHeader>
              <TableHeader>Profit/Loss Rate</TableHeader>
              <TableHeader>Profit/Loss Total</TableHeader>
              <TableHeader>Profit Margin</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              const { name, billRate, billableHours, fullyBurdenedRate } = values[index]
              const { revenue, hourlyProfit, profit } = getCalculations(billRate, billableHours, fullyBurdenedRate)

              totalBillRate += Number(billRate)
              totalFullyBurdenedRate += Number(fullyBurdenedRate)
              totalRevenue += revenue
              totalHourlyProfit += hourlyProfit
              totalProfit += profit

              return (
                <TableRow key={field.id}>
                  <TableCell>
                    {fields.length > 1 && (
                      <MinusCircleIcon className="text-smet-red cursor-pointer h-6 w-6" onClick={() => remove(index)} />
                    )}
                  </TableCell>
                  <TableCell>
                    <Field>
                      <Combobox
                        {...register(`resourcePlan.${index}.name`)}
                        options={personnel}
                        value={{ name }}
                        invalid={!!errors?.resourcePlan?.[index]?.name}
                      />
                    </Field>
                  </TableCell>
                  <TableCell>
                    <Field>
                      <Input
                        {...register(`resourcePlan.${index}.role`)}
                        invalid={!!errors?.resourcePlan?.[index]?.role}
                      />
                    </Field>
                  </TableCell>
                  <TableCell>
                    <Field>
                      <Input
                        {...register(`resourcePlan.${index}.billableHours`, {
                          setValueAs: (value) => value.toString()
                        })}
                        invalid={!!errors?.resourcePlan?.[index]?.billableHours}
                      />
                    </Field>
                  </TableCell>
                  <TableCell>
                    <Field>
                      <Input
                        {...register(`resourcePlan.${index}.billRate`, { setValueAs: (value) => value.toString() })}
                        invalid={!!errors?.resourcePlan?.[index]?.billRate}
                      />
                    </Field>
                  </TableCell>
                  <TableCell>
                    <Field>
                      <Input
                        {...register(`resourcePlan.${index}.fullyBurdenedRate`, {
                          setValueAs: (value) => value.toString()
                        })}
                        invalid={!!errors?.resourcePlan?.[index]?.fullyBurdenedRate}
                      />
                    </Field>
                  </TableCell>
                  <TableCell>{formatCurrency(revenue)}</TableCell>
                  <TableCell>{formatCurrency(hourlyProfit)} / hr</TableCell>
                  <TableCell>{formatCurrency(profit)}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>{formatCurrency(totalBillRate)} / hr</TableCell>
              <TableCell>{formatCurrency(totalFullyBurdenedRate)} / hr</TableCell>
              <TableCell>{formatCurrency(totalRevenue)}</TableCell>
              <TableCell>{formatCurrency(totalHourlyProfit)} / hr</TableCell>
              <TableCell>{formatCurrency(totalProfit)}</TableCell>
              <TableCell>{formatPercent(totalProfit / totalRevenue || 0)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {fields.length < 5 && (
        <PlusCircleIcon className="text-smet-primary-blue h-10 w-10 mt-4 self-center cursor-pointer" onClick={append} />
      )}
    </div>
  )
}

export default CreateSelfGuidedResourcePlanTable
