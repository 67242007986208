'use client'

import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'

import { SparklesIcon } from '@heroicons/react/24/solid'

const DeepChat = dynamic(() => import('deep-chat-react').then((mod) => mod.DeepChat), {
  ssr: false
})

interface Message {
  role: string
  text: string
}

const firstMessage = { role: 'ai', text: 'What can I help you with today?' }

const ChatAssistant = () => {
  const [isAssistantOpen, setIsAssistantOpen] = useState<boolean>(false)
  const [initialMessages, setInitialMessages] = useState<Message[]>([firstMessage])

  useEffect(() => {
    const getMessages = async () => {
      const threadId = sessionStorage.getItem('assistant_thread_id')

      if (threadId) {
        try {
          const res = await fetch('api/assistant/threads/messages', {
            method: 'POST',
            body: JSON.stringify({
              threadId
            })
          })

          res.json().then((data) => {
            const messages = [firstMessage].concat(data.messages)
            setInitialMessages(messages)
          })
        } catch {
          setInitialMessages([firstMessage])
        }
      }
    }

    if (!isAssistantOpen) {
      getMessages()
    }
  }, [isAssistantOpen])

  return (
    <>
      <div className="absolute bottom-24 right-8 z-10">
        {isAssistantOpen && (
          <DeepChat
            mixedFiles={{
              files: {
                maxNumberOfFiles: 1,
                acceptedFormats: '.pdf'
              }
            }}
            request={{ url: '/api/assistant/chat' }}
            requestInterceptor={(details: any) => {
              const threadId = sessionStorage.getItem('assistant_thread_id') || ''
              if (details.body instanceof FormData) {
                details.body.append('threadId', threadId)
              } else {
                details.body.threadId = threadId
              }
              return details
            }}
            responseInterceptor={(response: any) => {
              sessionStorage.setItem('assistant_thread_id', response.threadId)
              return response
            }}
            style={{ borderRadius: '10px' }}
            textInput={{ placeholder: { text: 'Type a message...' } }}
            initialMessages={initialMessages}
            errorMessages={{
              displayServiceErrorMessages: true
            }}
          />
        )}
      </div>
      <div className="absolute bottom-8 right-8 z-10">
        <div
          className="flex w-12 h-12 items-center justify-center bg-blue-600 rounded-full cursor-pointer"
          onClick={() => setIsAssistantOpen(!isAssistantOpen)}
        >
          <SparklesIcon className="text-white h-6 w-6" />
        </div>
      </div>
    </>
  )
}

export default ChatAssistant
