import { ReactNode } from 'react'
import { usePathname } from 'next/navigation'

import {
  ArrowLeftEndOnRectangleIcon,
  HomeIcon,
  BuildingOfficeIcon,
  BriefcaseIcon,
  RectangleGroupIcon,
  UsersIcon,
  UserGroupIcon
} from '@heroicons/react/16/solid'

import {
  Navbar,
  NavbarItem,
  NavbarLabel,
  NavbarSection,
  NavbarSpacer,
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  StackedLayout
} from '@/components/catalyst'

const items = [
  {
    label: 'Home',
    href: '/',
    icon: <HomeIcon />
  },
  {
    label: 'Company Profile',
    href: '/company-profile',
    icon: <BuildingOfficeIcon />
  },
  {
    label: 'Personnel',
    href: '/personnel',
    icon: <UserGroupIcon />
  },
  {
    label: 'Contacts',
    href: '/invite-user',
    icon: <UsersIcon />
  },
  {
    label: 'Projects',
    href: '/projects',
    icon: <RectangleGroupIcon />
  },
  {
    label: 'Create Opportunity',
    href: '/create-opportunity',
    icon: <BriefcaseIcon />
  }
]

const logoutItem = {
  label: 'Log Out',
  icon: <ArrowLeftEndOnRectangleIcon />
}

const CompanyNav = ({ children, logout }: { children: ReactNode; logout: () => void }) => {
  const pathname = usePathname()

  const navbar = (
    <Navbar>
      <NavbarSection className="max-lg:hidden">
        {items.map((item) => {
          return (
            <NavbarItem
              current={pathname === item.href || (pathname.startsWith(item.href) && item.href !== '/')}
              key={item.href}
              href={item.href}
            >
              {item.label}
            </NavbarItem>
          )
        })}
      </NavbarSection>
      <NavbarSpacer />
      <NavbarSection className="max-lg:hidden">
        <NavbarItem onClick={logout}>
          <NavbarLabel className="cursor-pointer">{logoutItem.label}</NavbarLabel>
        </NavbarItem>
      </NavbarSection>
    </Navbar>
  )

  const sidebar = (
    <Sidebar>
      <SidebarBody>
        <SidebarSection>
          {items.map((item) => {
            return (
              <SidebarItem key={item.href} href={item.href}>
                {item.icon}
                <SidebarLabel>{item.label}</SidebarLabel>
              </SidebarItem>
            )
          })}
        </SidebarSection>
      </SidebarBody>
      <SidebarFooter>
        <SidebarItem>
          {logoutItem.icon}
          <SidebarLabel className="cursor-pointer" onClick={logout}>
            {logoutItem.label}
          </SidebarLabel>
        </SidebarItem>
      </SidebarFooter>
    </Sidebar>
  )

  return (
    <StackedLayout navbar={navbar} sidebar={sidebar}>
      {children}
    </StackedLayout>
  )
}

export default CompanyNav
