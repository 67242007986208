import { z } from 'zod'

export const ResourceSchema = z.object({
  name: z.string().min(1, 'Required'),
  role: z.string().min(1, 'Required'),
  billRate: z.string().min(1, 'Required'),
  billableHours: z.string().min(1, 'Required'),
  fullyBurdenedRate: z.string().min(1, 'Required')
})

export const ResourcePlanSchema = z.object({
  resourcePlan: z.array(ResourceSchema),
  name: z.string().min(1, 'Required').max(50, 'Maximum 50 characters'),
  startDate: z.string().date(),
  endDate: z.string().date()
})

export type resourceType = z.infer<typeof ResourceSchema>
export type resourcePlanType = z.infer<typeof ResourcePlanSchema>
