import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'

import { useUser } from './UserContextProvider'

interface NotificationsContextType {
  notifications: boolean
  loading: boolean
  setNotifications: (notifications: boolean) => void
}

const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined)

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { user } = useUser()

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const res = await fetch('/api/notifications/feedback', {
          method: 'GET'
        })

        if (res.ok) {
          res.json().then((json) => setNotifications(json.data.unresolved || false))
        }
      } catch (error) {
        setNotifications(false)
      } finally {
        setLoading(false)
      }
    }

    if (user && user.attributes['custom:accountType'] === 'ba') {
      fetchNotifications()
    }
  }, [user])

  return (
    <NotificationsContext.Provider value={{ notifications, loading, setNotifications }}>
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotifications = () => {
  const context = useContext(NotificationsContext)

  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider')
  }

  return context
}
