import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/catalyst'
import { formatCurrency, formatPercent } from '@/components/salary-calculator/lib/numbro'

import { resourceType } from './schemas'
import {
  getFullyBurdenedCost,
  getHourlyProfit,
  getMaxProjectedProfit,
  getRevenue
} from '../../salary-calculator/lib/calculations'

const getCalculations = (billRate: string, billableHours: string, fullyBurdenedRate: string) => {
  const billRateNum = Number(billRate)
  const billableHoursNum = Number(billableHours)
  const fullyBurdenedRateNum = Number(fullyBurdenedRate)

  const revenue = getRevenue(billRateNum, billableHoursNum)
  const hourlyProfit = getHourlyProfit(billRateNum, fullyBurdenedRateNum)
  const fullyBurdenedCost = getFullyBurdenedCost(fullyBurdenedRateNum, billableHoursNum)
  const profit = getMaxProjectedProfit(billRateNum, fullyBurdenedCost, billableHoursNum)

  return {
    revenue,
    hourlyProfit,
    profit
  }
}

const ViewSelfGuidedResourcePlanTable = ({ resourcePlan }: { resourcePlan: resourceType[] }) => {
  let totalBillRate = 0
  let totalFullyBurdenedRate = 0
  let totalRevenue = 0
  let totalHourlyProfit = 0
  let totalProfit = 0

  return (
    <div className="flex flex-col">
      <Table grid dense>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Role</TableHeader>
            <TableHeader>Hours</TableHeader>
            <TableHeader>Bill Rate</TableHeader>
            <TableHeader>Fully Burdened Rate</TableHeader>
            <TableHeader>Revenue</TableHeader>
            <TableHeader>Profit/Loss Rate</TableHeader>
            <TableHeader>Profit/Loss Total</TableHeader>
            <TableHeader>Profit Margin</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {resourcePlan.map((row, index) => {
            const { name, role, billRate, billableHours, fullyBurdenedRate } = row
            const { revenue, hourlyProfit, profit } = getCalculations(billRate, billableHours, fullyBurdenedRate)

            totalBillRate += Number(billRate)
            totalFullyBurdenedRate += Number(fullyBurdenedRate)
            totalRevenue += revenue
            totalHourlyProfit += hourlyProfit
            totalProfit += profit

            return (
              <TableRow key={index}>
                <TableCell>{name}</TableCell>
                <TableCell>{role}</TableCell>
                <TableCell>{billableHours}</TableCell>
                <TableCell>{formatCurrency(Number(billRate))} / hr</TableCell>
                <TableCell>{formatCurrency(Number(fullyBurdenedRate))} / hr</TableCell>
                <TableCell>{formatCurrency(revenue)}</TableCell>
                <TableCell>{formatCurrency(hourlyProfit)} / hr</TableCell>
                <TableCell>{formatCurrency(profit)}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>{formatCurrency(totalBillRate)} / hr</TableCell>
            <TableCell>{formatCurrency(totalFullyBurdenedRate)} / hr</TableCell>
            <TableCell>{formatCurrency(totalRevenue)}</TableCell>
            <TableCell>{formatCurrency(totalHourlyProfit)} / hr</TableCell>
            <TableCell>{formatCurrency(totalProfit)}</TableCell>
            <TableCell>{formatPercent(totalProfit / totalRevenue || 0)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export default ViewSelfGuidedResourcePlanTable
