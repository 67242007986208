import { useCallback, useEffect, useState } from 'react'

import { CreateSelfGuidedResourcePlanCard, useUser, ViewSelfGuidedResourcePlanCard } from '@/components'
import { resourcePlanType } from '@/components/resource-plan/self/schemas'

import PromptCard from './PromptCard'

const SelfGuidedAssistant = () => {
  const { companyProfile } = useUser()

  const [isEditingResourcePlan, setIsEditingResourcePlan] = useState<boolean>(true)
  const [resourcePlan, setResourcePlan] = useState<resourcePlanType>()
  const [projectId, setProjectId] = useState<string>('')
  const [showAiAssistant, setShowAiAssistant] = useState<boolean>(false)
  const [isAssistantFetching, setIsAssistantFetching] = useState<boolean>(false)
  const [userQuery, setUserQuery] = useState<string>('')
  const [threadId, setThreadId] = useState<string>()
  const [assistantOutput, setAssistantOutput] = useState<string>('')

  const handleToggleDone = (resourcePlan: resourcePlanType) => {
    setResourcePlan(resourcePlan)
    setIsEditingResourcePlan(false)
  }

  const handleChange = useCallback((resourcePlan: resourcePlanType) => {
    setResourcePlan(resourcePlan)
  }, [])

  const getAiAssistantOutput = async (query: string | null, threadId?: string) => {
    setIsAssistantFetching(true)

    try {
      const res = await fetch('api/assistant/self-guided', {
        method: 'POST',
        body: JSON.stringify({
          query,
          threadId,
          resourcePlan,
          profile: companyProfile
        })
      })

      if (res.ok) {
        res.json().then((data) => {
          setAssistantOutput(JSON.parse(data.text).response)
          setThreadId(data.threadId)
          setUserQuery('')
        })
      } else {
        const error = await res.json().then((json) => json.error)
        throw new Error(error)
      }
    } catch (error) {
      alert(error)
      setAssistantOutput('')
      setThreadId(undefined)
    } finally {
      setIsAssistantFetching(false)
    }
  }

  const handleClearThread = () => {
    setThreadId(undefined)
    getAiAssistantOutput('', undefined)
  }

  useEffect(() => {
    if (showAiAssistant) {
      getAiAssistantOutput('', undefined)
    }
  }, [showAiAssistant])

  return (
    <>
      {isEditingResourcePlan ? (
        <CreateSelfGuidedResourcePlanCard
          resourcePlan={resourcePlan}
          handleDone={handleToggleDone}
          handleChange={handleChange}
        />
      ) : (
        <ViewSelfGuidedResourcePlanCard
          resourcePlan={resourcePlan}
          projectId={projectId}
          handleEdit={() => setIsEditingResourcePlan(true)}
          handleProjectCreation={(projectId: string) => setProjectId(projectId)}
          handleAiInsights={() => setShowAiAssistant(true)}
        />
      )}
      {showAiAssistant && (
        <PromptCard
          prompt={assistantOutput}
          isFetching={isAssistantFetching}
          input={userQuery}
          setInput={(input: string) => setUserQuery(input)}
          clearThread={handleClearThread}
          getAssistantOutput={(input: string) => getAiAssistantOutput(input, threadId)}
        />
      )}
    </>
  )
}

export default SelfGuidedAssistant
