import { ReactNode, useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'

import { ArrowLeftEndOnRectangleIcon, ChatBubbleLeftEllipsisIcon, HomeIcon } from '@heroicons/react/16/solid'

import { Dot, useNotifications } from '@/components'
import {
  Navbar,
  NavbarItem,
  NavbarLabel,
  NavbarSection,
  NavbarSpacer,
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  StackedLayout
} from '@/components/catalyst'

const items = [
  {
    label: 'Home',
    href: '/',
    icon: <HomeIcon />
  },
  {
    label: 'Requested Feedback',
    href: '/requested-feedback',
    icon: <ChatBubbleLeftEllipsisIcon />,
    notifications: true
  }
]

const logoutItem = {
  label: 'Log Out',
  icon: <ArrowLeftEndOnRectangleIcon />
}

const BusinessAdvisorNav = ({ children, logout }: { children: ReactNode; logout: () => void }) => {
  const [hasUnresolvedFeedback, setHasUnresolvedFeedback] = useState<boolean>(false)

  const { loading, notifications } = useNotifications()
  const pathname = usePathname()

  useEffect(() => {
    if (!loading) {
      setHasUnresolvedFeedback(notifications)
    }
  }, [loading, notifications])

  const navbar = (
    <Navbar>
      <NavbarSection className="max-lg:hidden">
        {items.map((item) => {
          const current = pathname === item.href || (pathname.startsWith(item.href) && item.href !== '/')

          return item.notifications && hasUnresolvedFeedback ? (
            <NavbarItem key={item.href} current={current} href={item.href}>
              <NavbarLabel>
                <div className="flex items-center">
                  {item.label}
                  <span className="ml-2">
                    <Dot />
                  </span>
                </div>
              </NavbarLabel>
            </NavbarItem>
          ) : (
            <NavbarItem key={item.href} current={current} href={item.href}>
              {item.label}
            </NavbarItem>
          )
        })}
      </NavbarSection>
      <NavbarSpacer />
      <NavbarSection className="max-lg:hidden">
        <NavbarItem className="cursor-pointer" onClick={logout}>
          <NavbarLabel className="cursor-pointer">{logoutItem.label}</NavbarLabel>
        </NavbarItem>
      </NavbarSection>
    </Navbar>
  )

  const sidebar = (
    <Sidebar>
      <SidebarBody>
        <SidebarSection>
          {items.map((item) => {
            return item.notifications && hasUnresolvedFeedback ? (
              <SidebarItem href={item.href}>
                {item.icon}
                <SidebarLabel>
                  <div className="flex items-center">
                    {item.label}
                    <span className="ml-2">
                      <Dot />
                    </span>
                  </div>
                </SidebarLabel>
              </SidebarItem>
            ) : (
              <SidebarItem href={item.href}>
                {item.icon}
                <SidebarLabel>{item.label}</SidebarLabel>
              </SidebarItem>
            )
          })}
        </SidebarSection>
      </SidebarBody>
      <SidebarFooter>
        <SidebarItem>
          {logoutItem.icon}
          <SidebarLabel className="cursor-pointer" onClick={logout}>
            {logoutItem.label}
          </SidebarLabel>
        </SidebarItem>
      </SidebarFooter>
    </Sidebar>
  )

  return (
    <StackedLayout navbar={navbar} sidebar={sidebar}>
      {children}
    </StackedLayout>
  )
}

export default BusinessAdvisorNav
